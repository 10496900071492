import React from 'react';
import Slider from "react-slick";
import { imgUrl } from '../../utilies/linkUrl';

const SliderHomeComponent = ({ data }) => {
  // Check if the sliders array is null or empty
  const isEmpty = !data.sliders || !Array.isArray(data.sliders) || data.sliders.length === 0;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <style jsx>{`
        .slider-active {
          background: #00173c; /* Use the correct property name 'background' */
        }

        .custom-slide  {
          width: 100%; /* Ensures the image covers the full width */
          height: auto; /* Maintains aspect ratio */
          object-fit: cover; /* Ensures the image covers the container without stretching */
        }
      `}</style>

      <section id="home" className="slider-area slider-four fix p-relative">
        {!isEmpty ? (
          <></>
        ) : (
          <Slider {...settings}>
            {data &&
              data.length > 0 &&
              data.slice(0, 5).map((s, idx) => (
                <div className="slider-active" key={idx}>
                  <div>
                    <img
                      src={`${imgUrl}/${s.image}`}
                      className="img-fluid custom-slide"
                      alt=""
                    />
                  </div>
                </div>
              ))}
          </Slider>
        )}
      </section>
    </>
  );
};

export default SliderHomeComponent;
