import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import aboutService from "../../services/aboutServices";
import { imgUrl } from "../../utilies/linkUrl";

const AboutUsHomeComponent = ({about}) => {

  return (
    <section className="about-area about-p p-relative fix ">
     
      <div className="container">
        <div className="row justify-content-center align-items-center">
        <div className="col-lg-12">
            <div className="section-title center-align  text-center mt-5">
            {about && about.title && (
                  <h2>{about.title ? about.title : ""}</h2>
                )}
            </div>


          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {about && about.image && (
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                  src={about.image ? `${imgUrl}${about.image}` : ""}
                  style={{ objectFit: "cover" }}
                  alt="img"
                />

              </div>
            )}
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div
              className="about-content s-about-content  wow fadeInRight  animated"
              data-animation="fadeInRight"
              data-delay=".4s"
            >
              <div className="about-title second-title pb-25">
                {/* <h5>About Us</h5> */}
                {/* {about && about.title && (
                  <h2>{about.title ? about.title : ""}</h2>
                )} */}
              </div>

              {about && about.description && (
                <>
                  <p
                    className="mb-4 "
                    dangerouslySetInnerHTML={{
                      __html: about.description.slice(0, 500) + ".......",
                    }}
                  ></p>
                </>
              )}

              <div className="slider-btn mt-10 mb-20">
                <Link to="/about-us" className="btn ss-btn smoth-scroll">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHomeComponent;
