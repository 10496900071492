import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteSetting from "../../services/settingServices";
import { imgUrl } from "../../utilies/linkUrl";

const FooterComponent = () => {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setContact(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);
  return (
    <footer
      className="footer-bg footer-p pt-90"
      style={{ background: "#005676" }}
    >
     

      <div className="footer-top pb-70">
        <div className="container">
          <div className="row justify-content-center">
            
          
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                {contact && contact.image && (
                  <div className="logo">
                    <h2>
                      <img
                        className="img-fluid"
                         width="70px"
                        src={
                          contact.image
                            ? `${imgUrl}${contact.image}`
                            : ""
                        }
                        alt="logo"
                      />
                    </h2>
                  </div>
                )}

                <div className="footer-link">
                  <p style={{ color: " #fff" }}>
                    {contact && contact.name ? contact.name : ""}
                  </p>
             
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Quicks Links</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/" className="text-white">
                        {" "}
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us" className="text-white">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/services" className="text-white">
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link to="/projects" className="text-white">
                    Projects
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" className="text-white">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-lg-4 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Contact Us</h2>
                </div>
                <div className="f-contact">
                  <ul>
                    {contact && (
                      <>
                        <li>
                          <i className="icon fal fa-map-marker-check mt-0"></i>
                          <span>
                            {contact.address ? contact.address : ""}
                          </span>
                        </li>
                        <li>
                          <i className="icon fal fa-phone mt-0"></i>
                          <span>
                            {contact.phone ?
                             <a href={`tel:${contact.phone}`} className="ml-2">{contact.phone}</a>
                            : ""}
                            <br />
                            {contact.phone2 ? 
                              <a href={`tel:${contact.phone2}`} className="ml-2">{contact.phone2}</a> : ""}
                          </span>
                        </li>
                        <li>
                          <i className="icon fal fa-envelope mt-0"></i>
                          <span>
                            <a
                              href={`mailto:${
                                contact.email ? contact.email : ""
                              }`}
                            >
                              {" "}
                              {contact.email ? contact.email : ""}
                            </a>
                          </span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="copy-text">
                Copyright &copy;{" "}
                {contact && contact.site_title ? contact.site_title : ""}. All
                rights reserved.|
                <span>
                  <a
                    className="text-white"
                    href="https://www.blackhawk.edu.np/"
                    target="_blank\"
                  >
                    {" "}
                    Developed By BLACKHAWK TEAM{" "}
                  </a>
                </span>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="footer-social">
              {contact && contact.pinterest && (
  <a
    href={`https://wa.me/${contact.pinterest.replace(/\D/g, '')}?text=Hello, I would like to know more about...`}
    target="_blank"
    rel="noopener noreferrer"
    title="WhatsApp"
    className="mr-3"
  >
    <i className="fab fa-whatsapp-square"></i>
  </a>
)}
                {contact && contact.facebook && (
                  <a
                    href={`${contact.facebook ? contact.facebook : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {contact && contact.linkedin
 && (
                  <a
                    href={`${
                      contact.linkedin
 ? contact.linkedin
 : ""
                    }`}
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                )}
                {contact && contact.twitter && (
                  <a
                    href={`${contact.twitter ? contact.twitter : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
                {contact && contact.instagram && (
                  <a
                    href={`${contact.instagram ? contact.instagram : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
