import React, { useEffect, useState } from "react";
import siteSetting from "../../services/settingServices";

const TopHeaderComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);

  return (
    <div className="header-top second-header">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          {/* Mobile View (Email on top, phone numbers, and icons below) */}
          <div className="d-block d-md-none col-12">
            {/* Email on top */}
            <div className="text-center mb-2">
              {data && data.email && (
                <div className="header-email">
                  <i className="icon fal fa-envelope" style={{marginRight:"5px",color: "#c308ac"}}></i>
                   <a href={`mailto:${data.email}`} className="ml-2">{data.email}</a> {/* Added space between icon and email */}
                </div>
              )}
            </div>

            {/* Phone numbers in the same line */}
            <div className="d-flex justify-content-center mb-2">
     
              {data && data.phone && (
                <div className="header-phone mr-4"> {/* Added margin to the right */}
                  <i className="icon fal fa-phone"style={{color: "#c308ac"}} ></i>
                   <a href={`tel:${data.phone}`} className="ml-2">{data.phone}</a> {/* Added space between icon and phone */}
                </div>
              )}
              {data && data.phone2 && (
                <div className="header-phone"  style={{marginLeft:"15px"}}>
                  <i className="icon fal fa-mobile"style={{marginRight:"5px",color: "#c308ac"}}></i>
                  <a href={`tel:${data.phone2}`} className="ml-2">{data.phone2}</a> {/* Added space between icon and second phone */}
                </div>
              )}
            </div>

            {/* Social media icons below */}
            <div className="text-center mt-2">
              <div className="header-social">
              {data && data.pinterest && (
  <a
    href={`https://wa.me/${data.pinterest.replace(/\D/g, '')}?text=Hello, I would like to know more about...`}
    target="_blank"
    rel="noopener noreferrer"
    title="WhatsApp"
    className="mr-3"
  >
    <i className="fab fa-whatsapp-square" style={{color:"green"}}></i>
  </a>
)}
                {data && data.facebook && (
                  <a
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                    className="mr-3"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {data && data.linkedin && (
                  <a
                    href={data.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="LinkedIn"
                    className="mr-3"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                )}
                {data && data.twitter && (
                  <a
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
                {data && data.instagram && (
                  <a
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Desktop View (Horizontal layout) */}
          <div className="d-none d-md-flex col-lg-8 col-md-8">
            <div className="header-cta d-flex">
              <ul className="list-unstyled d-flex mb-0">
                {/* Email */}
                {data && data.email && (
                  <li className="mr-4">
                    <i className="icon fal fa-envelope"></i>
                     <a href={`mailto:${data.email}`} className="ml-2">{data.email}</a> {/* Added space between icon and email */}
                  </li>
                )}

                {/* Phone numbers */}
                {data && data.phone && (
                  <li className="mr-4">
                    <i className="icon fal fa-phone"></i>
                     <a href={`tel:${data.phone}`} className="ml-2">{data.phone}</a> {/* Added space between icon and phone */}
                  </li>
                )}
                {data && data.phone2 && (
                  <li>
                    <i className="icon fal fa-mobile"></i>
                    <a href={`tel:${data.phone2}`} className="ml-2">{data.phone2}</a> {/* Added space between icon and second phone */}
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="d-none d-md-flex col-lg-4 col-md-4 text-right">
            <div className="header-social">
              <span>
              {data && data.pinterest && (
  <a
    href={`https://wa.me/${data.pinterest.replace(/\D/g, '')}?text=Hello, I would like to know more about...`}
    target="_blank"
    rel="noopener noreferrer"
    title="WhatsApp"
    className="mr-3"
  >
    <i className="fab fa-whatsapp-square" style={{color:"green"}}></i>
  </a>
)}

                {data && data.facebook && (
                  <a
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                    className="mr-3"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {data && data.linkedin && (
                  <a
                    href={data.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="LinkedIn"
                    className="mr-3"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                )}
                {data && data.twitter && (
                  <a
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
                {data && data.instagram && (
                  <a
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderComponent;
