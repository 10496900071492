import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import siteSetting from "../../services/settingServices";
import { imgUrl } from "../../utilies/linkUrl";

const NavBarComponent = () => {
  const [siteData, setSiteData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  useEffect(() => {
    const servicesData = async () => {
      setIsLoading(true);
      const service = await siteSetting();

      if (service) {
        setSiteData(service);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    servicesData();
  }, []);

  const handleNavLinkClick = () => {
    setIsCollapsed(true); // Close the navbar when a link is clicked
  };
  return (
    <div className="container-fluid">
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="logo" style={{ width: "70px" }}>
            <Link to="/">
              <img
                className="img-fluid"
                src={
                  siteData && siteData.image
                    ? `${imgUrl}${siteData.image}`
                    : ""
                }
                alt="logo"
              />
            </Link>
          </div>
          <Link to="/">
          <h6 className="ms-2 logo-text">Bricks Engineering & Construction Pvt. Ltd.</h6>

          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-md-5 ms-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                onClick={handleNavLinkClick} // Close navbar on click
                end
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about-us"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                onClick={handleNavLinkClick} // Close navbar on click
                end
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/services"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                onClick={handleNavLinkClick} // Close navbar on click
                end
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/projects"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                onClick={handleNavLinkClick} // Close navbar on click
                end
              >
                Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact-us"
                className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
                onClick={handleNavLinkClick} // Close navbar on click
                end
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <style jsx>{`
      @media (max-width: 576px) {
        .logo-text {
          white-space: normal; /* Allow wrapping */
          text-align: center;  /* Center align the text */
          margin-top: 5px;    /* Optional: adjust spacing */
        }
        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .navbar-collapse {
          width: 100%;
        }
      }
    `}</style>
  </div>

  );
};

export default NavBarComponent;
